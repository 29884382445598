import Vue from 'vue'
import Vuex from 'vuex';
import apiClient from "./apiClient";
import { i18n } from "../shared/i18n"
import signUpSession from "./signUpSession";
import { createStore } from 'vuex'
Vue.use(Vuex)


export default new createStore({
  modules: {
    session: signUpSession,
  },
  strict: import.meta.env.MODE !== 'production',
  state: {
    // Fixed for election
    electionUrl: null,

    partnerBranding: {
      logo: null,
      link: null
    },

    organisationBranding: {
      name: null,
      logo: null
    },

    // This value will get updated when a
    // new election json is fetched from server
    election: null,
    locales: [],
    currentLocale: i18n.global.locale,

    // Used to determine if the server is available
    lostConnectionToServer: false,

    // Overall theme color
    theme: 'light'
  },
  getters:{
    firstAvailableLocale(state){
      return state.locales.includes(i18n.global.locale) ? i18n.global.locale : state.locales[0]
    }
  },
  mutations: {
    setPartnerBranding(state, partnerBranding){
      state.partnerBranding = partnerBranding
    },
    setOrganisationBranding(state, organisationBranding){
      state.organisationBranding = organisationBranding
    },
    setElectionUrl(state, { electionUrl }){
      state.electionUrl = electionUrl
    },
    setElection(state, election){
      state.election = election
    },
    setLocales(state, locales){
      state.locales = locales
    },
    setLocale(state, locale){
      if(i18n.global.availableLocales.includes(locale)){
        state.currentLocale = locale
        i18n.global.locale = locale
      }

      document.getElementsByTagName('html')[0].lang = locale;
      const rtlLanguages = ['ar', 'dv', 'fa', 'ha', 'he', 'ks', 'ku', 'ps', 'ur', 'yi'];
      rtlLanguages.includes(locale)
        ? (document.getElementsByTagName('html')[0].dir = 'rtl')
        : (document.getElementsByTagName('html')[0].dir = 'ltr');
      document.title = state.election.title[state.currentLocale];
    },
  }
})
